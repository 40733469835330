// extracted by mini-css-extract-plugin
export var container = "menu-module--container--NLyw+";
export var icon = "menu-module--icon--WPexC";
export var iconContainer = "menu-module--iconContainer--pK7lV";
export var intro = "menu-module--intro--DVsS2";
export var leftContainer = "menu-module--leftContainer--Zqlyu";
export var list = "menu-module--list--9do2W";
export var listItem = "menu-module--listItem--4UDJ8";
export var logo = "menu-module--logo--YDGzv";
export var rightContainer = "menu-module--rightContainer--Rnwn7";
export var strikethrough = "menu-module--strikethrough--ArS0K";